:root {
  --primary-font: "Noto Serif Telugu", serif;
  --secondary-font: "Inter", sans-serif;
  --primary-color: #ffbcb7;
  --secondary-color: #1e7a8c;
  --box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

body {
  font-family: var(--secondary-font);
}

h1 {
  font-family: var(--primary-font);
  font-size: 80px;
}

h2 {
  font-family: var(--primary-font);
  font-size: 60px;
}

p {
  font-size: 16px;
  line-height: 1.8;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
