@media (max-width: 991.98px) {
  .website-link {
    display: block;
    align-items: center;
  }
  .project-card img {
    width: 70vw;
    height: auto;
  }
}
