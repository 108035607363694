.hero {
  background: var(--primary-color);
  padding: 60px 20px;
  text-align: center;
}

.hero h2 {
  font-family: var(--secondary-font);
  font-size: 28px;
  font-weight: 300;
}

.hero p {
  font-family: var(--secondary-font);
  font-size: 36px;
}

.btn-hero {
  width: 162px;
}

.btn-branding,
.btn-secondary {
  border-radius: 4;
  color: white;
  font-size: 18px;
  padding: 15px 30px;
}

.btn-branding {
  background: var(--secondary-color);
}

.btn-secondary {
  background: rgb(81, 81, 81);
}

.btn-secondary:hover {
  color: rgb(81, 81, 81);
  border: 1px solid rgb(81, 81, 81);
  background: var(--primary-color);
}

.btn-branding:hover {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.btn-branding-outline {
  color: var(--secondary-color);
  border-radius: 4;
  border: 1px solid var(--secondary-color);
  background: white;
  font-size: 18px;
  padding: 15px 30px;
  line-height: 2;
}

@media (max-width: 991.98px) {
  .hero h1 {
    font-size: 48px;
  }
  .hero h2 {
    font-size: 28px;
  }
}
