.about {
  padding: 60px 80px;
}

.about h2 {
  font-family: var(--secondary-font);
  font-size: 28px;
  font-weight: 300;
}

.about-eyebrow {
  font-family: var(--secondary-font);
  font-size: 36px;
  color: var(--secondary-color);
}

.about-description {
  font-size: 16px;
}

.personal-image {
  border: 5px solid var(--primary-color);
}

@media (max-width: 991.98px) {
  .about h1 {
    font-size: 48px;
  }
  .about h2 {
    font-size: 28px;
  }
  .about {
    text-align: center;
    padding: 20px 10px;
  }
  .about-description {
    text-align: left;
    padding: 0px 24px;
  }
}
